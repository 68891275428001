export const ROLE = {
  10: "GENESIS",
  20: "DISTRIBUTOR",
  30: "MERCHANT",
  40: "BRANCH"
};

export const ROLE_ID = {
  GENESIS: 10,
  DISTRIBUTOR: 20,
  MERCHANT: 30,
  BRANCH: 40
};

export const getRole = roleId => {
  return ROLE[ROLE_ID[roleId]];
};

export const getRoleId = roleId => {
  return ROLE_ID[roleId];
};

export const getChildRole = roleId => {
  return roleId < 40 && ROLE[roleId + 10];
};
