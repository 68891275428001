import Api from "../../services/api";

const paymentunits = {
  state: () => ({
    loading: false,
    data: []
  }),
  mutations: {
    SET_STATE(state, payload) {
      state[payload.name] = payload.value;
    }
  },
  actions: {
    async GET_PAYMENT_UNITS({ commit }) {
      commit("SET_STATE", { name: "loading", value: true });
      try {
        const resp = await Api.get("payment-units");

        commit("SET_STATE", {
          name: "data",
          value: resp.data
          // resp?.data?.map(val => ({
          //   ...val,
          //   label: val.name.toUpperCase()
          // })) || []
        });
        return resp;
      } catch (error) {
        console.error("GET_PAYMENT_UNITS error: ", error);
        return error;
      } finally {
        commit("SET_STATE", { name: "loading", value: false });
      }
    }
  },
  namespaced: true
};

export default paymentunits;
