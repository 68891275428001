/* eslint-disable no-unused-vars */
import Api from "../../services/api";
import ApiMcm from "../../services/apiMcm";

const wallets = {
  state: () => ({
    isLoading: false,
    wallets: [],
    tradeCryptos: []
  }),
  mutations: {
    SET_WALLETS(state, payload) {
      state.wallets = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_CRYPTOS(state, payload) {
      state.tradeCryptos = Object.entries(payload)
        .map(([key, value]) => value)
        .filter(currency => currency?.crypto);
    }
  },
  actions: {
    GET_WALLETS({ commit }) {
      commit("SET_LOADING", true);
      Api.get("wallets")
        .then(res => {
          commit("SET_LOADING", false);
          commit("SET_WALLETS", res.data);
        })
        .catch(error => {
          console.log("api/wallets error: ", error?.response?.data);
          commit("SET_LOADING", false);
        });
    },
    async CREATE_WALLET(_, params) {
      return await Api.post("wallets", params);
    },
    async GET_CRYPTOS({ commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await ApiMcm.get("common/currencies");
        commit("SET_CRYPTOS", res.data?.data || null);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async assignWalletsToBranches(
      _,
      { companyId, currencyCode, isActive } = {
        companyId: [],
        currencyCode: [],
        isActive: true
      }
    ) {
      return await Api.post("update-company-wallet", {
        companyId,
        currencyCode,
        isActive
      });
    }
  },
  getters: {},

  namespaced: true
};

export default wallets;
