import Api from "../../services/api";

const representative = {
  state: () => ({
    SELECTED_REPRESENTATIVE: null
  }),
  mutations: {
    SET_SELECTED_REPRESENTATIVE(state, representative) {
      representative.id = representative._id ?? representative.id;
      state.SELECTED_REPRESENTATIVE = representative;
    },
    CLEAR_SELECTED_REPRESENTATIVE(state) {
      state.SELECTED_REPRESENTATIVE = {};
    }
  },
  actions: {
    async GET_REPRESENTATIVE(_, representativeId) {
      return await Api.get("representatives/" + representativeId);
    },
    SET_SELECTED_REPRESENTATIVE({ commit }, representative) {
      commit("SET_SELECTED_REPRESENTATIVE", representative);
    },
    CLEAR_SELECTED_REPRESENTATIVE({ commit }) {
      commit("CLEAR_SELECTED_REPRESENTATIVE");
    },
    SET_CURRENT_REPRESENTATIVE_SELECTED_REPRESENTATIVE({ commit, rootState }) {
      const activeRepresentative = rootState.auth.representative;
      commit("SET_SELECTED_REPRESENTATIVE", activeRepresentative);
    },
    async DELETE_REPRESENTATIVE(_, deleteId) {
      return await Api.delete("representatives/" + deleteId);
    },
    async TOGGLE_REPRESENTATIVE_ACTIVE_STATUS(
      _,
      { representativeId, isActive = true }
    ) {
      return await Api.put("representatives/" + representativeId, { isActive });
    }
  },
  getters: {
    SELECTED_REPRESENTATIVE(state) {
      return state.SELECTED_REPRESENTATIVE;
    },
    SELECTED_REPRESENTATIVE_ID(state) {
      return state.SELECTED_REPRESENTATIVE
        ? state.SELECTED_REPRESENTATIVE.id
        : null;
    }
  },
  namespaced: true
};

export default representative;
