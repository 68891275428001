import Api from "@/services/api";
import JwtService from "@/services/jwt";

export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

export const OTP = "otpSend";
export const OTP_AGAIN = "otpAgain";
export const APP_LOGIN = "appLogin";

const SAVE_TOKENS = "saveTokens";

const auth = {
  state: () => ({
    errors: null,
    user: {},
    userRole: {},
    company: {},
    isAuthenticated: true
  }),
  mutations: {
    [SET_ERROR](state, error) {
      state.errors = error;
    },
    [SET_AUTH](state, user) {
      state.isAuthenticated = true;
      state.user = user;
      state.userRole = user.userRole;
      state.errors = {};
    },
    [SET_PASSWORD](state, password) {
      state.user.password = password;
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.user = {};
      state.userRole = {};
      state.errors = {};
      window.localStorage.clear();
      window.sessionStorage.clear();
    }
  },
  actions: {
    [SAVE_TOKENS](context, data) {
      JwtService.saveAuthToken(data.token);
      Api.setHeader();
    },
    [OTP](context, credentials) {
      return new Promise((resolve, reject) => {
        Api.post("auth/authenticate-admin-otp", credentials)
          .then(({ data }) => {
            context.dispatch(SAVE_TOKENS, data);
            resolve();
          })
          .catch(({ error }) => {
            reject(error);
          });
      });
    },
    [OTP_AGAIN](context, credentials) {
      return new Promise((resolve, reject) => {
        Api.post("auth/resend-otp", credentials)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    [APP_LOGIN](context, credentials) {
      return new Promise((resolve, reject) => {
        Api.post("auth/admin-login", credentials)
          .then(response => {
            const hasGoogle2FA = response.status === 207;
            resolve(hasGoogle2FA);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
    [REGISTER](context, credentials) {
      return new Promise(resolve => {
        Api.post("login", credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [VERIFY_AUTH](context) {
      if (JwtService.getAuthToken()) {
        Api.setHeader();
        Api.get("/me")
          .then(({ data }) => {
            context
              .dispatch("company/SET_MAIN_COMPANY", data.parent)
              .then(() => {
                context.commit(SET_AUTH, data);
              });
          })
          .catch(() => {
            context.dispatch(LOGOUT);
          });
      } else {
        context.commit(PURGE_AUTH);
      }
    },
    [UPDATE_PASSWORD](context, payload) {
      const password = payload;

      return Api.put("password", password).then(({ data }) => {
        context.commit(SET_PASSWORD, data);
        return data;
      });
    }
  },
  getters: {
    activeUser(state) {
      return state.user;
    },
    activeUserId(state) {
      return state.user?.id;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    userRole(state) {
      return state.userRole;
    },
    isMerchant(state) {
      return state.user?.roles && state.user.roles[0] === "ROLE_MERCHANT";
    },
    isDistributor(state) {
      return state.user?.roles && state.user.roles[0] === "ROLE_DISTRIBUTOR";
    }
  }
};

export default auth;
