/* eslint-disable no-console */
import Api from "../../services/api";
import { downloadUrl } from "@/utils/download-file";

const orders = {
  state: () => ({
    selectedOrder: {},
    viewOrder: {},
    selectedOrderMerchantId: null
  }),
  mutations: {
    SET_VIEW_ORDER(state, payload) {
      state.viewOrder = payload;
    },
    SET_SELECTED_ORDER(state, payload) {
      state.selectedOrder = payload;
      state.selectedOrderMerchantId = payload.merchantId;
    }
  },
  actions: {
    async DELETE_ORDER(state, orderId) {
      await Api.delete("/company-orders/" + orderId);
      state.commit("SET_VIEW_ORDER", {});
    },
    UPDATE_SELECTED_QR_CURRENCY(state, payload) {
      Api.post("update-selected-currency", payload)
        .then(() => {
          console.log("UPDATE_SELECTED_QR_CURRENCY:success");
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    async GET_ORDER({ commit }, payload) {
      try {
        const res = await Api.get("company-orders/" + payload);
        commit("SET_VIEW_ORDER", res.data);
        commit("SET_SELECTED_ORDER", res.data);
        return res.data;
      } catch (error) {
        commit("SET_VIEW_ORDER", null);
        console.log(error);
        return error;
      }
    },
    async GET_ORDERS_TOTAL(_, branchId) {
      const ordersTotals = await Api.get(
        `company-orders/${branchId}/admin-orders-total`
      );
      return ordersTotals.data;
    },
    async SET_SELECTED_ORDER(state, payload) {
      await state.commit("SET_SELECTED_ORDER", payload);
    },
    async CHECK_ORDER_STATUS(state, orderId) {
      return await Api.get(`company-orders/${orderId}`);
    },
    async GET_ORDER_RECEIPT(_, orderId) {
      return await Api.post("get-order-file", {
        order: orderId
      });
    },
    /**
     *
     * let formData = new FormData();
     * formData.append('order', 167);
     * formData.append('file', inputFile: {name: '', size: '', type: ''...});
     */
    async UPLOAD_ORDER_RECEIPT(_, formData) {
      return Api.post("image-upload", formData);
    },
    async DOWNLOAD_ORDER_RECEIPT(_, orderId) {
      try {
        const response = await Api.post("get-order-file", {
          order: orderId
        });
        const { data, file_type } = response.data;
        await downloadUrl({
          url: data,
          file_type,
          file_name: "order-receipt"
        });
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  },
  getters: {
    MAIN_COMPANY_ID(state, getters, rootState, rootGetters) {
      return rootGetters["company/MAIN_COMPANY_ID"];
    },
    VIEW_ORDER(state) {
      return state.viewOrder;
    },
    SELECTED_ORDER(state) {
      return state.selectedOrder;
    },
    SELECTED_ORDER_MERCHANT_ID(state) {
      return state.selectedOrderMerchantId;
    }
  },

  namespaced: true
};

export default orders;
