/**
 *
 * @param {string} key
 * @param {string} item
 */
export const setString = (key, item) => {
  window.sessionStorage.setItem(key, item);
};
/**
 *
 * @param {string} key
 * @returns {string}
 */
export const getString = key => {
  return window.sessionStorage.getItem(key);
};

/**
 *
 * @param {string} key
 * @param {Object} item
 */
export const setObject = (key, item) => {
  window.sessionStorage.setItem(key, JSON.stringify(item));
};
/**
 *
 * @param {string} key
 * @returns {Object}
 */
export const getObject = key => {
  return JSON.parse(window.sessionStorage.getItem(key));
};

/**
 *
 * @param {string} key
 */
export const removeSessionItem = key => {
  window.sessionStorage.removeItem(key);
};
