<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

.hide-tabs > div:not(.tab-content) {
  display: none;
}

.flex-1 {
  flex: 1;
}

.truncate {
  display: inline-block;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overlay-background .bg-dark {
  background-color: rgba(16, 18, 29, 0.95) !important;
}

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/

.body-class {
  &.modal {
    .modal-dialog {
      height: 100%;

      .modal-content {
        height: 75%;

        .modal-body {
          padding: 0;
        }
      }
    }
  }
}

.modal-header {
  h2 {
    height: 27px;
    max-height: 27px;
  }
}

.z-index-100 {
  z-index: 100;
}

.pull-up {
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  perspective: 1000px;
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
}
.pull-up:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  -moz-transform: translateY(-4px) scale(1.02);
  -ms-transform: translateY(-4px) scale(1.02);
  -o-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  z-index: 999;
}

.pull-up-only-shadow {
  transition: box-shadow 0.25s ease;

  &:hover {
    box-shadow: rgba(255, 255, 255, 1) 0 0 0 2px,
      rgba(50, 50, 93, 0.2) 0 0 10px 2px;
  }
}

.multiselect.is-invalid .multiselect__tags {
  border-color: #dc3545;
}

.live-feedback-error {
  font-size: 0.9rem;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}

.no-border-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.no-border-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/modules/config";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "App",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
