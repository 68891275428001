/* eslint-disable no-extra-boolean-cast */
import Api from "@/services/api";
import { ROLE_ID, ROLE, getRoleId } from "@/constants/roles";
import { areAllValuesEqualTo } from "../../utils/arrays-includes";

const company = {
  state: () => ({
    MAIN_COMPANY: null,
    SELECTED_COMPANY: JSON.parse(
      sessionStorage.getItem("_selectedCompany") || "{}"
    ),
    PARENT_COMPANY: JSON.parse(
      sessionStorage.getItem("_parentCompany") || "{}"
    ),
    kycKybDetailsLoading: false,
    kycKybDetails: null,
    documentStateSelects: null,
    kycFilesStatus: "rejected"
  }),
  mutations: {
    SET_MAIN_COMPANY(state, company) {
      state.MAIN_COMPANY = company;
    },
    SET_SELECTED_COMPANY(state, company) {
      company.id = company._id ?? company.id;
      state.SELECTED_COMPANY = company;
    },
    CLEAR_SELECTED_COMPANY(state) {
      state.SELECTED_COMPANY = {};
    },
    SET_PARENT_COMPANY(state, parent) {
      state.PARENT_COMPANY = parent;
    },
    CLEAR_PARENT_COMPANY(state) {
      state.PARENT_COMPANY = {};
    },
    SET_KYC_KYB_DETAILS_LOADING(state, payload) {
      state.kycKybDetailsLoading = payload;
    },
    SET_KYC_KYB_DETAILS(state, payload) {
      if (payload) {
        state.kycKybDetails = payload;
        state.kycKybDetails = {
          ...state.kycKybDetails,
          names: []
        };
        if (payload?.name && payload.name.length > 0) {
          const combinedNamesSurnamesEmails = payload.name.map(
            (firstName, index) => ({
              firstName,
              lastName: !!payload?.surname ? payload.surname[index] : "",
              email: !!payload?.email ? payload?.email[index] : ""
            })
          );
          state.kycKybDetails = {
            ...state.kycKybDetails,
            names: combinedNamesSurnamesEmails
          };
        }
        let defaultKycStatus = "rejected";
        const waitingStatuses = ["waiting", "uploaded"];
        if (
          "uboState" in payload &&
          "activitiesState" in payload &&
          "chartState" in payload &&
          "sourceOfFundState" in payload &&
          "idCardState" in payload
        ) {
          const {
            uboState,
            activitiesState,
            chartState,
            sourceOfFundState,
            idCardState
          } = payload;
          const statesObject = {
            uboState,
            activitiesState,
            chartState,
            sourceOfFundState,
            idCardState
          };
          const statesMappedArray = Object.values(statesObject).map(
            val => val.name
          );
          if (areAllValuesEqualTo(statesMappedArray, waitingStatuses)) {
            defaultKycStatus = "pending";
          } else {
            defaultKycStatus = payload.documentState ? "approved" : "rejected";
          }
        }
        state.kycFilesStatus = defaultKycStatus;
      }
    },
    SET_DOCUMENT_STATE_DETAIL_LIST(state, payload) {
      state.documentStateSelects = payload;
    }
  },
  actions: {
    async SET_MAIN_COMPANY({ commit }, company) {
      if (!this.getters["company/MAIN_COMPANY_ID"]) {
        commit("SET_MAIN_COMPANY", company);
      }
    },
    async GET_COMPANY(_, companyId) {
      return await Api.get("companies/" + companyId);
    },
    async GET_SUB_COMPANIES(_, companyId) {
      const subCompanies = await Api.get(
        "companies/" + companyId + "/companies"
      );

      return subCompanies.data;
    },
    async GET_REPRESENTATIVE(_, companyId) {
      const subCompanies = await Api.get(
        "companies/" + companyId + "/get-representative"
      );

      return [subCompanies.data];
    },
    async GET_USERS(_, companyId) {
      const users = await Api.get(`companies/${companyId}/users`);
      const deletedUsersCount = await Api.get(
        `companies/${companyId}/deleted-users-count`
      );
      return {
        users: users.data,
        deletedUsersCount: deletedUsersCount.data
      };
    },
    async GET_DELETED_COMPANIES({ state }) {
      const id = state.MAIN_COMPANY.id;
      const deletedCompanies = await Api.get(
        "companies/" + id + "/deleted-companies"
      );
      return deletedCompanies.data;
    },
    async GET_DELETED_USERS({ state }) {
      const id = state.MAIN_COMPANY.id;
      const deletedUsers = await Api.get("companies/" + id + "/deleted-users");
      return deletedUsers.data;
    },
    async DELETE_COMPANY(_, deleteId) {
      return await Api.delete("companies/" + deleteId);
    },
    async UNDELETE_COMPANY({ state }, undeleteId) {
      const id = state.MAIN_COMPANY.id;
      return await Api.put(
        "companies/" + id + "/undelete-company/" + undeleteId,
        { headers: { "Content-Type": "application/json", Accept: "*/*" } }
      );
    },
    async UNDELETE_USER({ state }, undeleteId) {
      const id = state.MAIN_COMPANY.id;
      return await Api.put("companies/" + id + "/undelete-user/" + undeleteId, {
        headers: { "Content-Type": "application/json", Accept: "*/*" }
      });
    },
    async GET_COMPANY_WALLETS({ state }) {
      const id = state.MAIN_COMPANY.id;
      const wallets = await Api.get("companies/" + id + "/company-wallets");
      return wallets.data;
    },
    SET_SELECTED_COMPANY({ commit }, company) {
      commit("SET_SELECTED_COMPANY", company);
    },
    CLEAR_SELECTED_COMPANY({ commit }) {
      commit("CLEAR_SELECTED_COMPANY");
    },
    CLEAR_PARENT_COMPANY({ commit }) {
      commit("CLEAR_PARENT_COMPANY");
    },
    SET_PARENT_COMPANY({ commit }, parent) {
      const mutation = parent ? "SET_PARENT_COMPANY" : "CLEAR_PARENT_COMPANY";
      commit(mutation, parent);
    },
    async TOGGLE_COMPANY_ACTIVE_STATUS(_, { companyId, isActive = true }) {
      // return await Api.put("companies/" + companyId, { isActive }); // old method
      return await Api.post("user-is-active-or-passive", {
        companyId,
        isActive
      });
    },
    async GET_PAYMENT_INFO_BY_MERCHANT(_, merchant) {
      return await Api.post("get-company-payment", { merchant });
    },
    async GET_KYC_KYB({ commit, state }, companyId) {
      commit("SET_KYC_KYB_DETAILS_LOADING", true);
      state.kycKybDetails = null;
      try {
        let formData = new FormData();
        formData.append("company", companyId);
        const res = await Api.post("get-company-document-file", formData);
        commit("SET_KYC_KYB_DETAILS", res.data?.data || undefined);
        commit("SET_KYC_KYB_DETAILS_LOADING", false);
        return res;
      } catch (error) {
        commit("SET_KYC_KYB_DETAILS_LOADING", false);
        throw error;
      }
    },
    async GET_DOCUMENT_STATE_DETAIL_LIST({ commit }) {
      const res = await Api.get("get-all-document-state-detail-list");
      commit("SET_DOCUMENT_STATE_DETAIL_LIST", res.data || []);
      return res.data;
    },
    async UPDATE_DOCUMENT_STATE(_, { value, companyId }) {
      const res = await Api.post("update-all-document-pdf-state", {
        company: companyId,
        uboState: value,
        activitiesState: value,
        chartState: value,
        sourceOfFundState: value,
        idCardState: value
      });
      return res;
    },
    async UPDATE_USER_DOCUMENT_STATE(_, { companyId, documentState }) {
      const res = await Api.post("update-user-document-state", {
        companyId,
        documentState
      });
      return res;
    },
    async ONFIDO_CHECK(_, formData) {
      return await Api.post("onfido-check-control", formData);
    }
  },
  getters: {
    MAIN_COMPANY(state) {
      return state.MAIN_COMPANY;
    },
    MAIN_COMPANY_ID(state) {
      return state.MAIN_COMPANY?.id;
    },
    SELECTED_COMPANY(state) {
      return state.SELECTED_COMPANY;
    },
    SELECTED_COMPANY_ID(state) {
      return state.SELECTED_COMPANY?.id;
    },
    SELECTED_COMPANY_ROLE(state) {
      return state.SELECTED_COMPANY?.companyRank ?? ROLE[10];
    },
    SELECTED_COMPANY_ROLE_ID(state) {
      return state.SELECTED_COMPANY?.companyRank
        ? ROLE_ID[state.SELECTED_COMPANY?.companyRank]
        : ROLE_ID.GENESIS;
    },
    PARENT_COMPANY(state) {
      return state.PARENT_COMPANY;
    },
    PARENT_COMPANY_REPRESENTATIVE_URI(state) {
      return state.PARENT_COMPANY?.representativeUri;
    },
    PARENT_COMPANY_ROLE_RANK(state) {
      return state.PARENT_COMPANY?.companyRank;
    },
    IS_SELECTED_COMPANY_DIST(state) {
      const roleId = getRoleId(state.SELECTED_COMPANY?.companyRank);
      return roleId === ROLE_ID.DISTRIBUTOR;
    },
    IS_SELECTED_COMPANY_MERCHANT(state) {
      const roleId = getRoleId(state.SELECTED_COMPANY?.companyRank);
      return roleId === ROLE_ID.MERCHANT;
    },
    IS_SELECTED_COMPANY_BRANCH(state) {
      const roleId = getRoleId(state.SELECTED_COMPANY?.companyRank);
      return roleId === ROLE_ID.BRANCH;
    },
    IS_SELECTED_COMPANY_MERCHANT_OR_BRANCH(state) {
      const roleId = getRoleId(state.SELECTED_COMPANY?.companyRank);
      return roleId === ROLE_ID.MERCHANT || roleId === ROLE_ID.BRANCH;
    }
  },
  namespaced: true
};

export default company;
