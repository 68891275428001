// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  SLOGAN: "New Way of Payment Collecting",
  MENU: {
    NEW: "New",
    EDIT: "Edit",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    GREET: "Hello",
    BACK: "Back"
  },
  ROUTES: {
    DASHBOARD: "Dashboard",
    HOME: "Home",
    SALES_MANAGEMENT: "Sales Management",
    ALL_SALES: "All Sales",
    NEW_SALE: "New Sale",
    QR_SALE: "New Sale with QR Code",
    SALE_DETAILS: "Sale Details",
    WALLET_MANAGEMENT: "Wallet Management",
    WALLETS: "Wallets",
    WITHDRAWALS: "Withdrawals",
    PERSONNEL_MANAGEMENT: "Personnel Management",
    PERSONNEL: "All Persnonel",
    NEW_PERSONNEL: "New Personnel",
    USER_MANAGEMENT: "User Management",
    USERS: "Users",
    NEW_USER: "New User",
    DELETED_USERS: "Deleted Users",
    PROFILE: "Profile",
    COMPANY: "Company",
    SETTINGS: "Settings",
    ROLES: "Roles",
    LOGS: "Logs",
    LOGOUT: "Logout",
    CHANGE_PASSWORD: "Change Password",
    CORPORATE: "Corporate",
    SALES_REPRESENTATIVES: "Sales Representatives",
    NEW_REPRESENTATIVE: "New Representative",
    COMPANIES: "Companies",
    BRANCHES: "Branches",
    BRANCH: "Branch",
    MEMBER_MERCHANTS: "Member Companies",
    TRANSACTIONS: "Transactions",
    TRANSACTIONS_LIST: "Transaction List",
    POS_LIST: "Hand Terminals",
    REFUNDED_TRANSACTIONS_LIST: "Refunded Transaction List",
    WALLET: "Cüzdan",
    KYC_KYB_REQUESTS: "KYC-KYB Requests",
    NEW_WALLET: "New Wallet",
    DISTS: "Distributors"
  },
  FORM: {
    ID: "Id",
    YES: "Yes",
    NO: "No",
    OK: "OK",
    TRY_AGAIN: "Try Again",
    SAVE: "Save",
    UPDATE: "Update",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    EMAIL: "Email",
    FIRST_NAME: "Name",
    LAST_NAME: "Family name",
    FULL_NAME: "Name familyname",
    PASSWORD: "Password",
    PASSWORD_CONFIRM: "Password Confirmation",
    PHONE: "Phone",
    LOGIN_WITH_GOOGLE: "Login with Google Two Factor Authentication",
    GOOGLE_SCAN_MESSAGE1: "Scan QR Code through",
    GOOGLE_SCAN_MESSAGE2: "",
    CLICK: "Click",
    WHEN_DONE: "when done",
    PHONE_OR_EMAIL: "Phone or Email",
    CONFIRM_PASSWORD: "Confirm Password",
    USERNAME: "Username",
    SUCCESS_TITLE: "Successful Operation",
    SUBMITTED: " successfully saved",
    UPDATED: " successfully updated",
    DELETED: " successfully deleted",
    ERROR_TITLE: "Error",
    ERROR: "An Error Occured.",
    PLEASE_SELECT: "Select an option",
    PAYMENT_UNIT: "Payment Unit",
    POS_NUMBER: "Hand Terminal Number",
    SELECT_A_PAYMENT_UNIT: "Select a payment unit",
    SELECT_A_CURRENCY_UNIT: "Select a currency unit",
    ASSIGNMENT_CONFIRMATION: "Assignment Confirmation",
    MUCH_OPTIONS_SELECTED: "{value} options selected.",
    UNASSIGNMENT_CONFIRMATION: "Unassignment Confirmation",
    ID_NO: "Identity Number",
    SIGNATURE_AUTH: "Signature Authorities",
    ADD_ROW: "Add Row"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
      WELCOME: "Welcome",
      NEW_HERE: "New Here?",
      CREATE_ACCOUNT: "Create New Account",
      LOGIN: "Login",
      OTP_VERIFICATION: "OTP Verification",
      TWO_FA: "Two Factor Authentication",
      TWO_FA_GOOGLE: "Two Factor Authentication with Google",
      TWO_FA_MESSAGE:
        "Please enter the code sent to your phone or email to login",
      TWO_FA_GOOGLE_MESSAGE:
        "Please enter the code from Google Authenticator app to login",
      VERIFICATION_CODE: "Enter verification code",
      SEND_AGAIN: "Send Again",
      LOADING_MESSAGE_TITLE: "Hang On",
      LOADING_MESSAGE_SUBTITLE: "Gathering Your Info..."
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      AGREEMENT_TEXT: "I Agree the",
      AGREEMENT_LINK: "terms and conditions",
      SUCCESS: "Your account has been successfuly registered."
    },
    VALIDATION: {
      INVALID: "{name} is not valid",
      REQUIRED: "{name} is required",
      MIN_LENGTH: "The minimum length of the {name} must be {min} characters.",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {name} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      PASSWORD_DONT_MATCH: "Passwords do not match."
    }
  },
  LIST: {
    LEVEL: "Level",
    CREATED_AT: "Created at",
    UPDATED_AT: "Updated at"
  },
  COMPANY: {
    COMPANY: "Company",
    COMPANY_INFO: "Company Info",
    BRANCH: "Branch",
    SALES_REPRESENTATIVE: "Sales Representative",
    USER: "User",
    TITLE: "Title",
    TAX_ADMINISTRATION: "Tax Administration",
    TAX_CITY: "Tax Office City",
    TAX_NUMBER: "Tax Number",
    QUICK_SALE: "Quick Sale",
    MAIL_INFO_MESSAGE: "Generated user credentials will be sent to this email.",
    domestic_transfer_infos: "Domestic Transfer Informations",
    overseas_transfer_infos: "Overseas Transfer Informations",
    bank: "Bank",
    account_name: "Account Name",
    account_type: "Account Type",
    account_no: "Account No",
    buyer: "Buyer Info(Person or Company Name)",
    city: "City",
    swift_code: "Swift Code",
    update_payment_info: "Update Payment Info",
    parentTitle: "Parent Title",
    dist_info: "Distributor Info",
    merchant_info: "Merchant Info",
    branch_info: "Branch Info",
    affiliated_merchants: "Affiliated Merchants",
    affiliated_branches: "Affiliated Branches",
    register_new_dist: "Register New Distributor",
    register_new_merch: "Register New Merchant",
    register_new_branch: "Register New Branch"
  },
  PAGES: {
    SALES_MANAGEMENT: {
      ORDER_DETAILS: {
        SALE_DETAILS: "Sale Details",
        DELETE_ORDER: "Delete Order",
        ORDER_INFOS: "Order Informations",
        ORDER_ID: "Order ID",
        REFUND_WALLET_ADDRESS: "Refund Wallet Address",
        UPLOAD_BANK_RECEIPT: "Upload Bank Receipt",
        DOWNLOAD_BANK_RECEIPT: "Download Bank Receipt"
      },
      LINK_SALES: {
        NEW_SALE: "New Sale with Link",
        ORDER_INFO: "Order Info",
        ORDER_AMOUNT: "Order Amount",
        COMMISSION_RATE: "Commission Rate",
        DEDUCT_COMMISSION: "Deduct Commission from Order Amount?",
        COMMISSION_AMOUNT: "Commission Amount",
        ORDER_TOTAL: "Order Total",
        CREATE_LINK: "Create Link",
        ORDER_IDENTITY: "Order Identity",
        TOTAL_AMOUNT: "Total Amount",
        QUANTITY: "Quantity",
        TOTAL_QUANTITY: "Total Quantity",
        WALLET: "Wallet Address",
        TRANSACTION_STATUS: "Transaction Status",
        TRANSACTION_SUCCESS: "Transaction Success",
        TRANSACTION_ERROR: "Transaction Error"
      },
      LIST: {
        ORDER_ID: "Order id",
        PRICE: "Price",
        COMMISSION: "Comission",
        TOTAL: "Total",
        STATUS: "Status",
        DATE: "Date",
        SALE: "Sale",
        PENDING: "Pending",
        COMPLETED: "Completed",
        PROCESSING: "Processing",
        FAILED: "Failed",
        CANCELLED: "Cancelled",
        NOT_ENOUGH: "Not Enough",
        ALL_DATES: "All dates",
        SELECT_START_DATE: "Select start date",
        SELECT_END_DATE: "Select end date",
        EXPORT_TO_PDF: "PDF",
        EXPORT_TO_EXCEL: "EXCEL",
        PLATFORM: "Platform",
        PAYMENT_STATE: "Merchant Payment",
        PAYMENT: "Payment",
        LEVELS: {
          DISTRIBUTOR: "Distributor",
          MERCHANT: "Merchant",
          BRANCH: "Branch"
        },
        PAYMENT_STATES: {
          1: "Pending",
          2: "Processing",
          3: "Completed",
          4: "Cancelled"
        },
        ORDER_STATUSES: {
          0: "Pending",
          1: "Completed",
          2: "Processing",
          3: "Failed",
          4: "Cancelled",
          5: "Insufficient balance",
          6: "Refund",
          7: "Submitted",
          8: "Transaction received",
          9: "Queued",
          10: "Pending authorization",
          11: "Rejected",
          12: "Blocked"
        },
        PAYMENT_STATE_UPDATED: "Payment status updated",
        AFFILIATED_COMPANIES: "Affiliated Companies",
        CRYPTO_QUANTITY: "Crypto Quantity",
        CRYPTO_QUANTITY_PAID: "Paid Crypto Quantity",
        CUSTOMER: "Customer",
        SERVICE_COMMISSION: "Service Commission"
      },
      POS: {
        POS: "Hand Terminal",
        SERIAL: "Serial No",
        SYSTEM_DEFINED: "System defined",
        POS_SETUP: "Hand Terminal Setup",
        DELIVERED: "Delivered",
        HAS_DELIVERED: "Has delivered",
        REASON: "Reason",
        UNDELIVERED_REASON: "Undelivery reason",
        DELIVERY_FORM: "Delivery form",
        PASS: "Pass",
        POS_DESCRIPTION1: "Hand Terminal Description 1",
        POS_DESCRIPTION2: "Hand Terminal Description 2",
        SELECTED_POS_COUNT: "Selected Hand Terminal:",
        ASSIGN_TO_DISTRIBUTOR: "Assign to Distributor"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "New Sale with QR Code",
          CUSTOMER_INFO: "Customer Info",
          SUCCESS_TITLE: "Payment Received.",
          SUCCESS_SUBTITLE: "Thank you.",
          ERROR_SUBTITLE: "Please Try Again.",
          SCAN_QR_CODE: "Scan QR Code",
          MIRACLE_CUSTOMER: "Miracle Customer",
          NOT_MIRACLE_CUSTOMER: "Not Miracle Cust.",
          AN_ERROR_HAS_OCCURRED: "An error has occurred.",
          TRY_AGAIN: "Try Again",
          ALL_ORDERS: "All Orders",
          NEW_ORDER: "New Order"
        },
        ORDER_COMPLETED: "Order completed.",
        ORDER_AMOUNT_IS: "Transaction Amount",
        MISSING_ORDER_PAYMENT: "Missing order payment has been made.",
        ORDER_CRYPTO_AMOUNT_IS: "Crypto Amount"
      },
      PROFILE: {
        PROFILE_PAGE: "Profile Page"
      }
    },
    DASHBOARD: {
      CRYPTO: "Crypto",
      CURRENCY: "Currency",
      LATEST_TRANSACTIONS: "Latest Transactions",
      CRYPTO_AMOUNT: "Amount",
      MONTHLY_ORDER_STATUS: "Monthly Order Status",
      CURRENCY_TYPE: "Currency Type",
      TOTAL_TRANSACTION_AMOUNT: "Total Transaction Amount",
      AMOUNT_PAID: "Amount Paid",
      AMOUNT_AWAITING_PAYMENT: "Amount Awaiting Payment"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  VALIDATION: {
    NUMERIC: "Accepts only numeric values.",
    MIN_VALUE: "{name} minimum value must be {min}",
    ONLY_INTEGERS: "Only integers are accepted.",
    MAX_LENGTH: "It can contain a maximum of {num} characters.",
    REQUIRED_FIELD: "Required field",
    EMAIL_NOT_VALID: "Email not valid.",
    DUPLICATED_VALUES: "You entered the same {field} more than once.",
    FILE_SIZE_LESS_THAN: "The file size must be less than {size}.",
    ACCEPTED_FILE_TYPES: "Accepted file types; {types}"
  },
  COMMON: {
    ACTIVATE: "Activate",
    DEACTIVATE: "Make Passive",
    NO_DATA_AVAILABLE: "No data available.",
    INFORM: "Information",
    ACCESS: "Access",
    GRANT_ACCESS: "Grant Access",
    RESTRICT_ACCESS: "Restrict Access",
    payment: "Payment",
    remove: "Remove",
    file_drop_zone_label: "Drop file here or <u>click here</u> to upload.",
    file_drop_zone_label_dragging: "Release to drop file here.",
    hide_low_balances: "Hide Low Balances",
    refresh: "Refresh",
    show_file: "Show File",
    reject: "Reject",
    inspect: "Inspect",
    can_select_multi: "You can select multiple options.",
    multi_select_limit_text: "and {count} other selected"
  },
  devices: {
    pos: "Pos",
    vp: "Sanal Pos",
    pnl: "Panel",
    wc: "WooCommerce"
  },
  kyc_kyb: {
    idCard: "ID Card",
    commerceNumber: "Chamber of Commerce number of all involved entities",
    taxNumber: "Tax Identification Number",
    sourceOfFund: "Source of funds",
    files_companyActivities:
      "A list of company activities and an overview of the countries with which the company cooperates",
    files_orgChart: "Org chart of all involved entitiets",
    files_ubo: "UBO register Extract",
    submitted: "Your request has been sent successfully.",
    waiting: "Waiting",
    uploaded: "Uploaded",
    approved: "Approved",
    denied: "Denied",
    kyc_kyc_details: "KYC-KYB Details",
    status: "Status",
    revoke_confirmation: "Revoke confirmation",
    id_cards_including_ubos:
      "ID Card of Directors and Shareholders including UBO’s"
  },
  wallets: {
    walletCode: "Wallet Code",
    currencyName: "Crypto Name",
    currencyCode: "Crypto Code",
    currencyIcon: "Crypto Icon (CDN)",
    selectCrypto: "Select Crypto",
    assign_to_branch: "Assign To Branch",
    please_select_branches_first: "Please select Branch/Branches first.",
    selectedWallets: "Selected Wallets",
    assignConfirmWarning:
      "Crypto wallets will be assigned to selected branch/branches, are you sure?",
    unassign_to_branch: "Unassign from Branch",
    unassignConfirmWarning:
      "Crypto wallets will be unassigned from selected branch/branches, are you sure?",
    select_all: "SELECT ALL",
    unselect_all: "DESELECT ALL"
  }
};
