import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import i18n from "@/plugins/vue-i18n";

Vue.use(Router);

const isAuthenticated = (to, from, next) => {
  if (store.getters["isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
};
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/MainLayout"),
      beforeEnter: isAuthenticated,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: { title: i18n.t("ROUTES.HOME") }
        },
        {
          path: "/companies",
          component: () => import("@/view/layout/CommonLayout"),
          children: [
            {
              path: "company-list/",
              name: "company-list",
              component: () => import("@/view/pages/companies/CompanyList.vue"),
              props: true,
              meta: { title: i18n.t("ROUTES.COMPANIES") }
            },
            {
              path: "company-list/company",
              name: "company",
              component: () => import("@/view/pages/common/Company.vue"),
              props: true,
              meta: { title: i18n.t("COMPANY.COMPANY") }
            },
            {
              path: "company-list/new-company",
              name: "new-company",
              component: () => import("@/view/pages/common/Company.vue"),
              meta: {
                title: `${i18n.t("MENU.NEW")} ${i18n.t("COMPANY.COMPANY")}`
              }
            },
            {
              path: "company-list/mcm-wallet/:companyId",
              name: "company-mcm-wallet",
              component: () => import("@/view/pages/McmWallet.vue"),
              meta: { title: i18n.t("ROUTES.WALLET") }
            },
            {
              path: "kyc-kyb-requests/",
              name: "kyc-kyb-requests",
              component: () =>
                import("@/view/pages/companies/KycKybRequests.vue"),
              meta: {
                title: i18n.t("ROUTES.KYC_KYB_REQUESTS")
              }
            }
          ]
        },
        {
          path: "/transactions",
          component: () => import("@/view/layout/CommonLayout"),
          children: [
            {
              path: "order-list",
              name: "order-list",
              component: () =>
                import("@/view/pages/transactions/OrderList.vue"),
              meta: { title: i18n.t("ROUTES.ALL_SALES") }
            },
            {
              path: "refunded-order-list",
              name: "refunded-order-list",
              component: () =>
                import("@/view/pages/transactions/OrderList.vue"),
              meta: { title: i18n.t("ROUTES.REFUNDED_TRANSACTIONS_LIST") }
            },
            {
              path: "order-details/:orderId",
              name: "order-details",
              component: () =>
                import("@/view/pages/transactions/OrderDetails.vue"),
              meta: { title: i18n.t("ROUTES.SALE_DETAILS") }
            }
          ]
        },
        {
          path: "/pos",
          component: () => import("@/view/layout/CommonLayout"),
          children: [
            {
              path: "pos-list",
              name: "pos-list",
              component: () => import("@/view/pages/pos/PosList.vue"),
              meta: { title: i18n.t("ROUTES.POS_LIST") }
            },
            {
              path: "pos-list/pos-details",
              name: "pos-details",
              component: () => import("@/view/pages/common/Pos.vue"),
              meta: {
                title: `${i18n.t("MENU.NEW")} ${i18n.t(
                  "PAGES.SALES_MANAGEMENT.POS.POS"
                )}`
              }
            }
          ]
        },
        {
          path: "/corporate",
          component: () => import("@/view/layout/CommonLayout"),
          children: [
            {
              path: "admin-list",
              name: "admin-list",
              component: () => import("@/view/pages/corporate/AdminList.vue"),
              meta: { title: i18n.t("ROUTES.USERS") }
            },
            {
              path: "admin-list/admin",
              name: "admin",
              component: () => import("@/view/pages/common/User.vue"),
              props: true,
              meta: { title: i18n.t("ROUTES.USERS") }
            },
            {
              path: "admin-list/new-admin",
              name: "new-admin",
              component: () => import("@/view/pages/common/User.vue"),
              meta: { title: i18n.t("ROUTES.USERS") }
            }

            /* {
              path: "representative-list",
              name: "representative-list",
              component: () =>
                import("@/view/pages/corporate/RepresentativeList.vue"),
              meta: { title: i18n.t("ROUTES.SALES_REPRESENTATIVES") }
            },
            {
              path: "representative-list/representative",
              name: "representative",
              component: () => import("@/view/pages/common/Representative.vue"),
              props: true,
              meta: { title: i18n.t("ROUTES.SALES_REPRESENTATIVES") }
            },
            {
              path: "representative-list/new-representative",
              name: "new-representative",
              component: () => import("@/view/pages/common/Representative.vue"),
              meta: { title: i18n.t("ROUTES.SALES_REPRESENTATIVES") }
            } */
          ]
        },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("@/view/pages/ChangePassword.vue"),
          meta: { title: i18n.t("ROUTES.CHANGE_PASSWORD") }
        },
        {
          path: "/wallets",
          name: "wallets",
          component: () => import("@/view/pages/Wallets.vue"),
          meta: { title: i18n.t("ROUTES.WALLETS") }
        },
        {
          path: "/wallets/new-wallet",
          name: "new-wallet",
          component: () => import("@/view/pages/NewWallet.vue"),
          meta: { title: i18n.t("ROUTES.NEW_WALLET") }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/Login"),
          meta: { title: i18n.t("AUTH.LOGIN.TITLE") }
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/Login"),
          meta: { title: i18n.t("AUTH.LOGIN.TITLE") }
        }
      ]
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/view/pages/ForgotPassword.vue"),
      meta: { title: i18n.t("ROUTES.CHANGE_PASSWORD") }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Error.vue")
    }
  ]
});

const DEFAULT_TITLE = "MiraclePay Admin Panel";
router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta?.title ? to.meta.title : DEFAULT_TITLE;
  });
});

export default router;
