import Api from "../../services/api";

const dashboard = {
  state: () => ({
    loading: false,
    data: []
  }),
  mutations: {
    SET_STATE(state, payload) {
      state[payload.name] = payload.value;
    }
  },
  actions: {
    async GET_DASHBOARD_REPORT() {
      const report = await Api.get("report/dashboard-report");
      return report.data;
    },
    async GET_TRANSACTIONS_END_OF_THE_DAY_REPORT({ commit }) {
      commit("SET_STATE", { name: "loading", value: true });
      try {
        const resp = await Api.get(
          "company/get-transactions-end-of-day-report"
        );

        commit("SET_STATE", {
          name: "data",
          value: resp?.data || []
        });
        return resp;
      } catch (error) {
        console.error("GET_TRANSACTIONS_END_OF_THE_DAY_REPORT error: ", error);
        return error;
      } finally {
        commit("SET_STATE", { name: "loading", value: false });
      }
    },
    async GET_DASHBOARD_SUMMARY_REPORT() {
      const report = await Api.get("company-orders/get-dashboard-report");
      return report.data;
    }
  },
  namespaced: true
};

export default dashboard;
