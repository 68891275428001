import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import Api from "@/services/api";
import { VERIFY_AUTH } from "@/store/modules/auth";
import { RESET_LAYOUT_CONFIG } from "@/store/modules/config";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/plugins/vue-i18n";
// import "@/plugins/portal-vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/perfect-scrollbar";
import "@/plugins/highlight-js";
import "@/plugins/inline-svg";
import "@/plugins/apexcharts";
import "@/plugins/metronic";
// import "@mdi/font/css/materialdesignicons.css";
// import "@/plugins/formvalidation";
// import "@/plugins/datatable";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { dateTimeFilter } from "./utils/date-filter";
import {
  getObject,
  getString,
  setObject,
  setString,
  removeSessionItem
} from "./utils/session-storage.helper";

Vue.prototype.$dateTimeFilter = dateTimeFilter;

Vue.use(VueSweetalert2);

Vue.use(require("vue-moment"));

// API service init
Api.init();

// Remove this to disable mock API
// Mock.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.filter("capitalize", value => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("upperCase", value => {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("filterDate", value => {
  return dateTimeFilter(value);
});

Vue.filter("filterDateTime", value => {
  return dateTimeFilter(value, "date-time");
});

/**
 *
 * @param {string} key
 * @param {string} item
 */
Vue.prototype.$setString = (key, item) => {
  setString(key, item);
};
/**
 *
 * @param {string} key
 * @returns {string}
 */
Vue.prototype.$getString = key => getString(key);

/**
 *
 * @param {string} key
 * @param {Object} item
 */
Vue.prototype.$setObject = (key, item) => {
  setObject(key, item);
};
/**
 *
 * @param {string} key
 * @returns {Object}
 */
Vue.prototype.$getObject = key => getObject(key);

/**
 *
 * @param {string} key
 * @returns
 */
Vue.prototype.$removeSessionItem = key => {
  removeSessionItem(key);
};

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
