import Api from "../../services/api";

const pos = {
  state: () => ({
    SELECTED_POS: null,
    SELECTED_POSES: []
  }),
  mutations: {
    SET_SELECTED_POS(state, pos) {
      pos.id = pos._id ?? pos.id;
      state.SELECTED_POS = pos;
    },
    CLEAR_SELECTED_POS(state) {
      state.SELECTED_POS = {};
    },
    SET_POSES_TO_ASSIGN(state, poses) {
      poses.forEach(pos => (pos.id = pos._id ?? pos.id));
      state.SELECTED_POSES = poses;
    },
    CLEAR_SELECTED_POSES(state) {
      state.SELECTED_POSES = [];
    }
  },
  actions: {
    async GET_POS(_, posId) {
      return await Api.get("pos/" + posId);
    },
    SET_SELECTED_POS({ commit }, pos) {
      commit("SET_SELECTED_POS", pos);
    },
    CLEAR_SELECTED_POS({ commit }) {
      commit("CLEAR_SELECTED_POS");
    },
    SET_POSES_TO_ASSIGN({ commit }, poses) {
      commit("SET_POSES_TO_ASSIGN", poses);
    },
    CLEAR_SELECTED_POSES({ commit }) {
      commit("CLEAR_SELECTED_POSES");
    },
    SET_CURRENT_POS_SELECTED_POS({ commit, rootState }) {
      const activeRepresentative = rootState.auth.pos;
      commit("SET_SELECTED_POS", activeRepresentative);
    },
    async DELETE_POS(_, deleteId) {
      return await Api.delete("pos/" + deleteId);
    },
    async TOGGLE_POS_ACTIVE_STATUS(_, { posId, isActive = true }) {
      return await Api.put("pos/" + posId, { isActive });
    }
  },
  getters: {
    SELECTED_POS(state) {
      return state.SELECTED_POS;
    },
    SELECTED_POS_ID(state) {
      return state.SELECTED_POS ? state.SELECTED_POS.id : null;
    },
    POSES_TO_ASSIGN(state) {
      return state.SELECTED_POSES;
    }
  },
  namespaced: true
};

export default pos;
