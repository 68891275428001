const clearItem = key => {
  window.sessionStorage.removeItem(key);
};
const storageItem = (key, item) => {
  clearItem(key);
  window.sessionStorage.setItem(key, JSON.stringify(item));
};

const mutations = {
  ["company/SET_SELECTED_COMPANY"]: payload => {
    storageItem("_selectedCompany", payload);
  },
  ["company/CLEAR_SELECTED_COMPANY"]: () => {
    clearItem("_selectedCompany");
  },
  ["company/SET_PARENT_COMPANY"]: payload => {
    storageItem("_parentCompany", payload);
  },
  ["company/CLEAR_PARENT_COMPANY"]: () => {
    clearItem("_parentCompany");
  }
};

const statePersistorPlugin = store => {
  store.subscribe(mutation => {
    /* if (type === "SOME_MUTATION_TYPE") {
        // do some things with payload
    }
    ...
    */

    const { type, payload } = mutation;
    if (type in mutations) {
      mutations[type](payload);
    }
  });
};

export default statePersistorPlugin;
