/* eslint-disable no-unused-vars */
import ApiMcm from "../../services/apiMcm";

const mcmCountries = {
  state: () => ({
    isLoading: false,
    countries: []
  }),
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_DATA(state, payload) {
      state.countries = Object.entries(payload).map(([key, value]) => value);
    }
  },
  actions: {
    async GET_COUNTRIES({ commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await ApiMcm.get("common/countries");
        commit("SET_DATA", res.data?.data || null);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    countries(state) {
      return state.countries;
    }
  },
  namespaced: true
};

export default mcmCountries;
