import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import htmlClass from "./modules/htmlclass";
import config from "./modules/config";
import breadcrumbs from "./modules/breadcrumbs";
// import profile from "./modules/profile";

import orders from "./modules/orders";
import wallets from "./modules/wallet";
import company from "./modules/company";
import user from "./modules/user";
import admin from "./modules/admin";
import representative from "./modules/representative";
import pos from "./modules/pos";
import paymentunits from "./modules/paymentunits";
import dashboard from "./modules/dashboard";
import mcmWallet from "./modules/mcmWallet";
import mcmCountries from "./modules/mcmCountries";

// custom plugins
import statePersistorPlugin from "./plugins/state-persistor";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    orders,
    wallets,
    company,
    user,
    admin,
    representative,
    pos,
    paymentunits,
    dashboard,
    mcmWallet,
    mcmCountries
  },
  plugins: [statePersistorPlugin]
});
